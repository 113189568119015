@import "@/assets/scss/style.scss";

.wrapper {
    margin: auto;
    width: 80%;
    text-align: left;
    margin-top: 3%;
    height: 100%;
    position: relative;
    border-radius: 10px;
    padding: 15px 15px 0 15px;

    .configurator-buttons {
        margin-top: 1rem;
        padding: 0 8px;
    }
}

.innerWrapper {
    height: 100%;
    border-style: none;
}

.ant-layout-content {
    overflow: hidden;
    border-left: 0px solid $grey-4;
}
.menu {
    border-style: none;
}
.sider {
    background-color: $white-two;
    border-style: none;
    flex: 0 0 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
    width: 150px !important;
}
.ant-layout {
    height: 100%;
}

.ant-layout-content {
    background-color: #fff;
}

.ant-menu-inline .ant-menu-item::after {
    left: 0;
    right: auto;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6f7ff00;
}

.menu-title {
    padding-bottom: 25px;
    margin-left: -60px;
    font-size: 0.9rem;
    font-weight: 450;
    color: #262626;
}

.product-div {
    width: fit-content;
    margin: 15px 0px;
}
.bold {
    font-size: 0.9rem;
    font-weight: 450;
    color: #262626;
}

.first_col {
    padding: 8px;
    text-align: left;
}

.second_col {
    padding: 8px;
    text-align: right;
}

.light-blue {
    background: $very-light-blue;
}

.blue {
    background: $roltek-blue;
}

.popup-notifications-margin {
    margin-top: 20px;
}

@media (max-width: 1200px) {
    .popup-notifications-margin {
        margin-top: 20px;
        margin-right: 15px;
    }
}

@media (max-width: 900px) {
    .popup-notifications-margin {
        margin-top: 5px;
        margin-right: 30px;
    }
}

@media (max-width: 900px) {
    .wrapper {
        width: 80%;
        padding: 5px 5px 0 5px;
        overflow: auto;
    }

    .innerWrapper {
        height: 100%;
        border-style: none;
    }
}

@media (max-width: 500px) {
    .wrapper {
        width: 90%;
    }
}
